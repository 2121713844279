import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faScrewdriverWrench } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { faClipboardUser } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";

import { useUser } from "../../context/providers/userContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast from "react-hot-toast";

const Navbar = () => {
  const { isLoggedIn, logout, user } = useUser();

  const navigate = useNavigate();

  const admin = user?.roles.some((role) => role.name === "admin");

  const start = () => {
    const timeOutId = setTimeout(() => {
      hiddenMenu();
    }, 5000);
    return () => clearTimeout(timeOutId);
  };

  const hiddenMenu = () => {
    const dropdown = document.getElementById("menu");
    const buttonMenuClose = document.getElementById("button-menu-close");
    const buttonMenuOpen = document.getElementById("button-menu-open");
    buttonMenuOpen.style.transform = "scale(1)";
    buttonMenuClose.style.transform = "scale(0)";
    dropdown.style.clipPath = "inset(0 100% 0 0)";
  };

  const showMenu = () => {
    const dropdown = document.getElementById("menu");
    const buttonMenuClose = document.getElementById("button-menu-close");
    const buttonMenuOpen = document.getElementById("button-menu-open");
    buttonMenuOpen.style.transform = "scale(0)";
    buttonMenuClose.style.transform = "scale(1)";
    dropdown.style.clipPath = "inset(0 0 0 0)";
  };


  const handleLogout = async () => {
    try {
      await logout();
      toast.success("Sesion cerrada!", {
        position: "bottom-right",
      });
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (window.innerWidth >= 1024) {
      showMenu();
      start();
    }
  }, []);

  return (
    <div className="App">
      <header>
        <div className="icon-menu">
          <div
            className="buttom-menu-header-close"
            id="button-menu-close"
            onClick={hiddenMenu}
            style={{ transform: "scale(0)" }}
          >
            <img
              src="https://i.postimg.cc/c1FDgs0P/cerrar.png"
              alt="Cerrar menu"
              className="close-menu"
            />
          </div>
          <div
            className="buttom-menu-header"
            id="button-menu-open"
            onClick={showMenu}
          >
            <img
              src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1708784141/Volunteers/assets/175_Icono_Volunteers_nio4cr.png"
              alt="Menu"
              className="open-menu"
            />
            <h4 className=" font-darumadrop">MENU</h4>
          </div>
        </div>
        <ul className="dropdown" id="menu">
          <li className="primary-bottom font-darumadrop">
            <Link to="/" onClick={hiddenMenu}>
              <FontAwesomeIcon icon={faHouse} />
              INICIO
            </Link>
          </li>
          <li className="primary-bottom font-darumadrop">
            <Link to="/content" onClick={hiddenMenu}>
              <FontAwesomeIcon icon={faSearch} />
              CONTENIDO
            </Link>
          </li>
          {isLoggedIn ? (
            <>
              {admin ? (
                <>
                  <li className="primary-bottom open-submenu font-darumadrop">
                    <Link to="/admin-panel" onClick={hiddenMenu}>
                      <FontAwesomeIcon icon={faScrewdriverWrench} />
                      ADMIN
                    </Link>
                  </li>
                </>
              ) : (
                <></>
              )}
              <li className="primary-bottom open-submenu font-darumadrop">
                <Link to={`/account-free/`} onClick={hiddenMenu}>
                  <FontAwesomeIcon icon={faUser} />
                  MI CUENTA
                </Link>
              </li>
              <li className="primary-bottom open-submenu font-darumadrop">
                <Link
                  to="/"
                  className="button-logout"
                  onClick={() => {
                    handleLogout();
                    hiddenMenu();
                  }}
                >
                  <FontAwesomeIcon icon={faArrowRightFromBracket} />
                  LOGOUT
                </Link>
              </li>
            </>
          ) : (
            <li className="primary-bottom login font-darumadrop">
              <Link to="/login" onClick={hiddenMenu}>
                <FontAwesomeIcon icon={faClipboardUser} />
                INICIAR SESION
              </Link>
            </li>
          )}
          <li className="primary-bottom open-submenu font-darumadrop">
            <Link to="/nosotros" onClick={hiddenMenu}>
              <FontAwesomeIcon icon={faUsers} />
              NOSOTROS
            </Link>
          </li>
        </ul>
        <div className="text-header-container">
          <h2 className="text-header-animated">
            Juntos podemos ser la voz de la naturaleza, protegiendo nuestro
            hogar para las generaciones futuras.
          </h2>
        </div>
        {/* <div className="admin-navbar-container"> */}
          {admin ? (
            <Link to="/admin-panel" className="admin-navbar">
              <FontAwesomeIcon icon={faScrewdriverWrench} />
            </Link>
          ) : (
            <></>
          )}
        {/* </div> */}
      </header>
    </div>
  );
};

export default Navbar;
