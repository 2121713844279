import React, { useEffect, useState } from "react";
import { useTareas } from "../../context/providers/tareasContext";
import InfiniteScroll from "react-infinite-scroll-component";
import TareasRandom from "../../components/TareasRandom";

import("../../styles/content.css");

const Content = () => {
  const { getTareasRandom } = useTareas();
  const [taskRandom, setTaskRandom] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  const fetchData = async () => {
    if (hasMore) {
      const res = await getTareasRandom(page, 3);
      setTaskRandom([...taskRandom, ...res]);
      setPage(page + 1);
      setHasMore(res.length > 0);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <main>
      <section className="section-content">
        <article className="title">
          <h1 className="title-content-other-activity font-darumadrop">
            EXPLORA OTRAS ACTIVIDADES
          </h1>
          <div className="content-card">
            <div className="infinite-scroll-container">
              <InfiniteScroll
                dataLength={taskRandom.length}
                next={fetchData}
                hasMore={hasMore}
                loader={<h4>Cargando...</h4>}
              >
                {taskRandom.map((tarea) => (
                  <div key={tarea._id}>
                    <TareasRandom tarea={tarea} />
                    <hr/>
                  </div>
                ))}
              </InfiniteScroll>
            </div>
          </div>
        </article>
      </section>
    </main>
  );
};

export default Content;
