import React, { useState } from "react";
import Enter from "../components/ui/Enter";
import ChatBotRetune from "../components/Dashboard/ChatBotRetune";
import { BsWhatsapp } from "react-icons/bs";
import { useUser } from "../context/providers/userContext";
import { Link } from "react-router-dom";
import { IoMdCloseCircle } from "react-icons/io";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faCircleXmark,
  faGear,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";

import("../styles/index.css");

const Index = () => {
  const { user, isLoggedIn, logout } = useUser();
  const [botBool, setBotBool] = useState(true);
  const [viewTextP1, setviewTextP1] = useState(false);
  const [viewTextP2, setviewTextP2] = useState(false);

  const viewP1 = () => {
    if (viewTextP1) {
      closeViewP1();
    } else {
      document.getElementById("content-home-p1").style.clipPath =
        "inset(0 0 0 0)";
      closeViewP2();
      setviewTextP1(true);
    }
  };
  const closeViewP1 = () => {
    document.getElementById("content-home-p1").style.clipPath =
      "inset(0 0 100% 0)";
    setviewTextP1(false);
  };
  const viewP2 = () => {
    if (viewTextP2) {
      closeViewP2();
    } else {
      document.getElementById("content-home-p2").style.clipPath =
        "inset(0 0 0 0)";
      closeViewP1();
      setviewTextP2(true);
    }
  };
  const closeViewP2 = () => {
    document.getElementById("content-home-p2").style.clipPath =
      "inset(0 0 100% 0)";
    setviewTextP2(false);
  };

  const botView = () => {
    const dropdown = document.getElementById("chatBot");
    setBotBool(false);
    dropdown.style.clipPath = "inset(0 0 0 0)";
  };
  const botClose = () => {
    const dropdown = document.getElementById("chatBot");
    setBotBool(true);
    dropdown.style.clipPath = "inset(100% 0 0% 0)";
  };

  const handleLogout = async () => {
    try {
      await logout();
      toast.success("Sesion cerrada!", {
        position: "bottom-right",
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <main className="homepage-main">
      {/* <Enter /> */}
      <h1 className="welcome font-darumadrop">
        <Link to="/">INICIO</Link>
      </h1>
      <div className="article-home">
        <img
          src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1735063122/Volunteers/assets/Group_1_hyr4rk.png"
          alt="FondoHome"
        />
      </div>
      <p className="content-home-p1" id="content-home-p1">
        El coach ambientalista es como el superhéroe del planeta, motivando a
        todos a reducir su huella ecológica y proteger nuestro hogar. No solo
        guía a las personas hacia un cambio positivo, sino que también lidera
        acciones voluntarias en momentos de crisis ambiental. ¡Es hora de
        unirnos y salvar el planeta juntos! <br />
        <IoMdCloseCircle
          className="button-chatbot-close-home"
          id="button-chatbot-hidden"
          onClick={closeViewP1}
        />
      </p>
      <p className="content-home-p2" id="content-home-p2">
        Imagina a un coach ambientalista como el guerrero verde que trabaja con
        todos: individuos, empresas, escuelas, ¡incluso tu comunidad! Su misión
        es empoderar a todos para tomar acciones que protejan el medio ambiente
        y mejoren nuestras vidas. ¡Es el héroe que todos necesitamos para un
        futuro más verde y brillante! <br />
        <IoMdCloseCircle
          className="button-chatbot-close-home"
          id="button-chatbot-hidden"
          onClick={closeViewP2}
        />
      </p>
      <section className="section-home">
        <article className="article-footer">
          <div className="buttons-home">
          <a href="https://wa.link/7iv8gd" target="_blank"><BsWhatsapp /></a>
            <div className="buttons-home-container">
              <div className="button-home font-darumadrop">
                <Link to="/content">
                  <h2>CONTENIDO</h2>
                </Link>
              </div>
              <div className="button-home font-darumadrop">
                <Link to="/nosotros">
                  <h2>NOSOTROS</h2>
                </Link>
              </div>
            </div>
          </div>
          <div className="content-home">
            <h2
              onClick={() => {
                viewP1();
              }}
              id="objetivos-coach"
            >
              OBJETIVOS PRINCIPALES DE UN COACH AMBIENTALISTA
            </h2>

            <h2
              onClick={() => {
                viewP2();
              }}
            >
              ¿COMO SE PUEDE DESEMPEÑAR UN COACH AMBIENTALISTA EN LA SOCIEDAD?
            </h2>
          </div>
          <div className="account-home">
            <Link to="/ods" className="home-ods-icon font-darumadrop">
              <img
                src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722632276/Volunteers/assets/icon-ods_cx6szs.png"
                alt="Icono ODS"
              />
              ODS
            </Link>
            <div className="account-home-text-img">
              <div className="account-home-img">
                <img
                  src={
                    user?.image && user?.image?.url
                      ? user?.image?.url
                      : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1690634531/Volunteers/perfilImage/account_e6qpmh.svg"
                  }
                  alt="Foto de perfil"
                />
              </div>
              <div className="account-home-text ">
                {isLoggedIn ? (
                  <Link to="/account-free/" className="font-darumadrop">
                    <h2>MI CUENTA</h2>
                  </Link>
                ) : (
                  <Link to="/login">
                    <h2 className="font-darumadrop">MI CUENTA</h2>
                  </Link>
                )}
                <p>Ingresa a tu cuenta para subir tus actividades.</p>
              </div>
            </div>
          </div>
          <iframe
            className="iframe-chatbot-home"
            id="chatBot"
            src="https://retune.so/share/chat/11ee5794-2bd8-34f0-899f-036ecb8650de/widget"
            title="Chatbot de re:tune.so"
          />
          <article className="article-button-home">
            <div className="button-home-container">
              {isLoggedIn && (
                <Link to="/config-perfil">
                  <FontAwesomeIcon icon={faGear} />
                  <hr />
                  Configurar
                </Link>
              )}
              <div className="button-chatbot-home-footer font-darumadrop">
                {botBool && (
                  <img
                    src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1721954263/Volunteers/assets/ROBIA_eoiwr4.png"
                    className="button-chatbot-home"
                    id="button-chatbot-view"
                    onClick={botView}
                    alt="icono ChatBot"
                  />
                )}
                {!botBool && (
                  <IoMdCloseCircle
                    className="button-chatbot-close-home"
                    id="button-chatbot-hidden"
                    onClick={botClose}
                  />
                )}
                Ayuda
              </div>
              {isLoggedIn ? (
                <button
                  onClick={() => {
                    handleLogout();
                  }}
                >
                  CERRAR SESION
                </button>
              ) : (
                <Link to="/signup">
                  <button className="font-darumadrop">REGISTRARSE</button>
                </Link>
              )}
            </div>
          </article>
        </article>
      </section>
    </main>
  );
};
export default Index;
