import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTareas } from "../../context/providers/tareasContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpenReader } from "@fortawesome/free-solid-svg-icons";

const Activity = ({ tarea, userTarea }) => {
  const { getTarea } = useTareas();

  const navigate = useNavigate();

  const animation = () => {
    window.addEventListener("scroll", function () {
      const element = document.getElementById("activity-id");

      const triggerAt = window.innerHeight * 0.1;

      if (this.window.scrollY >= triggerAt) {
        element?.classList.add("active");
      }
    });

    window.addEventListener("scroll", function () {
      const div = document.getElementById("image-animation");
      const triggerAt = window.innerHeight * 0.2;

      if (this.window.scrollY >= triggerAt) {
        div?.classList.add("active");
      }
    });
  };

  const view = () => {
    document.getElementById(`more-activity${tarea.number}`).style.display =
      "block";
    document.getElementById(`low-activity${tarea.number}`).style.display =
      "none";
  };
  const hide = () => {
    document.getElementById(`more-activity${tarea.number}`).style.display =
      "none";
    document.getElementById(`low-activity${tarea.number}`).style.display =
      "block";
  };

  useEffect(() => {
    animation();
  }, []);

  return (
    <div className="card-content activity-id" id="activity-id">
      <Link to={`ods-activity-${tarea.number}`} className="ods-card-activity">
        <img
          src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722639527/Volunteers/assets/ODS_tareas_gogzqr.png"
          alt="Sistema ODS"
        />
      </Link>
      <img
        className="imagen-fondo-tarea"
        src={
          userTarea.image && userTarea.image.url
            ? userTarea.image.url
            : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1708114402/tmp-1-1708114401974.png"
        }
        alt="Imagen de tarea"
      />
      <div className="activity-text">
        <hr className="hr-activities"></hr>
        <div className="title-activity-card" id={`low-activity${tarea.number}`}>
          <span className="number-activity title-activity-account">
            {tarea.number}. {tarea.title}
          </span>
          <p id={`activity${tarea.number}`} className="text-activity">
            {tarea.description}
          </p>

          <div className="card-imgpng-free-container">
            {userTarea.image && userTarea.image.url ? (
              <>
                <div className="tarea-user-container">
                  <Link
                    onClick={async () => {
                      await getTarea(userTarea?._id);
                      navigate(`/details/${userTarea?._id}`);
                    }}
                    className="card-imgpng-free card-imgpng-free-animation"
                    id="image-animation"
                  >
                    <img
                      src={
                        userTarea && userTarea.image
                          ? userTarea.image.url
                          : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1690574586/mundo_ldar8i.png"
                      }
                      alt="Imagen de tarea"
                    />
                  </Link>
                  <div className="description-container">
                    <p>{userTarea?.description}</p>
                  </div>
                </div>
              </>
            ) : (
              <div className="video-activities-container">
                {/* <video
                  className="video-activity"
                  src="https://res.cloudinary.com/dd8a6qc45/video/upload/v1708440014/Volunteers/Video/night_sky_540p_iduip2.mp4"
                  controls
                /> */}
              </div>
            )}
          </div>
          <div className="span-more-low-container">
            <span
              className="number-activity icon-more-activity-container font-darumadrop"
              onClick={() => {
                view();
              }}
            >Subir Actividad <FontAwesomeIcon icon={faBookOpenReader} />
            </span>
          </div>
        </div>

        <div id={`more-activity${tarea.number}`} style={{ display: "none" }}>
          <span className="number-activity">
            {tarea.number}. {tarea.title},
          </span>
          <p className="text-activity">{tarea.description}</p>
          <div className="span-more-low-container">
            <span
              className="number-activity span-more-low"
              onClick={() => {
                hide();
              }}
            >
              Volver... <FontAwesomeIcon icon={faBookOpenReader} />
            </span>
          </div>
          {userTarea.image && userTarea.image.url ? (
            <div className="video-activities-container">
              {/* <video
                className="video-activity"
                src="https://res.cloudinary.com/dd8a6qc45/video/upload/v1708440014/Volunteers/Video/night_sky_540p_iduip2.mp4"
                controls
              /> */}
            </div>
          ) : (
            <>
              <div className="tarea-user-container">
                <Link
                  onClick={async () => {
                    await getTarea(userTarea?._id);
                    navigate(`/details/${userTarea?._id}`);
                  }}
                  className="card-imgpng-free card-imgpng-free-animation"
                  id="image-animation"
                >
                  <img
                    src={
                      userTarea && userTarea.image
                        ? userTarea.image.url
                        : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1734642016/Volunteers/assets/logo_upload_zddk5k.png"
                    }
                    alt="Imagen de tarea"
                  />
                </Link>
                <div className="description-container">
                  <p>{userTarea?.description}</p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Activity;
