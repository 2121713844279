import React, { useState } from "react";
import Enter from "../components/ui/Enter";
import ChatBotRetune from "../components/Dashboard/ChatBotRetune";
import { BsWhatsapp } from "react-icons/bs";
import { useUser } from "../context/providers/userContext";
import { Link } from "react-router-dom";
import { IoMdCloseCircle } from "react-icons/io";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faCircleXmark,
  faGear,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";

import("../styles/indexDesktop.css");

const IndexDesktop = () => {
  const { user, isLoggedIn, logout } = useUser();
  const [botBool, setBotBool] = useState(true);
  const [viewTextP1, setviewTextP1] = useState(false);
  const [viewTextP2, setviewTextP2] = useState(false);

  const viewP1 = () => {
    if (viewTextP1) {
      closeViewP1();
    } else {
      document.getElementById("content-home-p1").style.clipPath =
        "inset(0 0 0 0)";
      closeViewP2();
      setviewTextP1(true);
    }
  };
  const closeViewP1 = () => {
    document.getElementById("content-home-p1").style.clipPath =
      "inset(0 0 0 100%)";
    setviewTextP1(false);
  };
  const viewP2 = () => {
    if (viewTextP2) {
      closeViewP2();
    } else {
      document.getElementById("content-home-p2").style.clipPath =
        "inset(0 0 0 0)";
      closeViewP1();
      setviewTextP2(true);
    }
  };
  const closeViewP2 = () => {
    document.getElementById("content-home-p2").style.clipPath =
      "inset(0 0 0 100%)";
    setviewTextP2(false);
  };

  const botView = () => {
    const dropdown = document.getElementById("chatBot");
    setBotBool(false);
    dropdown.style.clipPath = "inset(0 0 0 0)";
  };
  const botClose = () => {
    const dropdown = document.getElementById("chatBot");
    dropdown.style.clipPath = "inset(0 0 0 100%)";
    setBotBool(true);
  };

  const handleLogout = async () => {
    try {
      await logout();
      toast.success("Sesion cerrada!", {
        position: "bottom-right",
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <main className="homepage-desktop-main">
      <div className="homepage-desktop-main-container">
        {/* <Enter /> */}
        <div className="homepage-desktop-header">
          <div className="homepage-desktop-header-img-container">
            <img
              src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1710177344/Volunteers/assets/TRIANGULO-NEGRO-VOLUNTEERS-1080p_qllgq2.png"
              alt="Logo Volunteers"
            />
          </div>
          <div className="homepage-desktop-header-icons">
            {botBool ? (
              <Link
                onClick={botView}
                className="button-chatbot-home"
                id="button-chatbot-view"
              >
                <lord-icon
                  src="https://cdn.lordicon.com/ckdooote.json"
                  trigger="loop"
                  delay="1000"
                  colors="primary:#aff02e,secondary:#f8ffef"
                ></lord-icon>
                <h2 className="font-darumadrop">Chat</h2>
              </Link>
            ) : (
              <IoMdCloseCircle
                className="button-chatbot-close-home"
                id="button-chatbot-hidden"
                onClick={() => {
                  botClose();
                }}
              />
            )}
            <Link to="https://wa.link/n82c1j" target="_blank">
              <lord-icon
                src="https://cdn.lordicon.com/lzhauhfx.json"
                trigger="loop"
                delay="1000"
                colors="primary:#aff02e,secondary:#f8ffef"
              ></lord-icon>
              <h2 className="font-darumadrop">Contacto</h2>
            </Link>
          </div>
          <div className="homepage-desktop-header-img-container">
            <img
              src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1735332194/Volunteers/assets/FORUM_LOGO_1_bihzb3.png"
              alt="Logo Volunteers"
            />
          </div>
        </div>
        <div className="homepage-desktop-section-1">
          <h1 className="homepage-desktop-title font-darumadrop">
            <Link to="/">BIENVENIDO</Link>
          </h1>
          <h1 className="homepage-desktop-title font-darumadrop">
            <Link to="/">VOLUNTEERS</Link>
          </h1>
        </div>

        <div className="homepage-desktop-background-img">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1735063122/Volunteers/assets/Group_1_hyr4rk.png"
            alt="FondoHome"
          />
        </div>

        <div className="homepage-desktop-section-2">
          <div className="homepage-desktop-section-2-left">
            <div className="desktop-section-2-titles font-darumadrop">
              <h2
                onClick={() => {
                  viewP1();
                }}
                id="objetivos-coach"
              >
                OBJETIVOS
                <br />
                VOLUNTEERS
              </h2>

              <h2
                onClick={() => {
                  viewP2();
                }}
              >
                COACH
                <br />
                AMBIENTAL
              </h2>
            </div>
          </div>
          <div className="homepage-desktop-section-2-rigth ">
            <div className="desktop-section-2-users">
              <span>Usuarios Registrados: </span>
              <span>30</span>
            </div>
            <div className="desktop-section-2-activities">
              <span>Actividades Realizadas:</span>
              <div className="desktop-section-2-activites-bar"></div>
            </div>
            <div className="desktop-section-2-certificates">
              <span>Certificaciones Generadas:</span>
              <div className="desktop-section-2-certificates-bar"></div>
            </div>
          </div>
        </div>

        <div className="homepage-desktop-section-3">
          <div className="homepage-desktop-bottons-2">
            <Link to="/ods">
              <lord-icon
                src="https://cdn.lordicon.com/ayvhsttz.json"
                trigger="loop"
                delay="1000"
                colors="primary:#aff02e,secondary:#f8ffef"
              ></lord-icon>
              <h2 className="font-darumadrop">ODS</h2>
            </Link>
            <Link to="/nosotros">
              <lord-icon
                src="https://cdn.lordicon.com/aksvbzmu.json"
                trigger="loop"
                delay="1000"
                colors="primary:#aff02e,secondary:#f8ffef"
              ></lord-icon>
              <h2 className="font-darumadrop">Nosotros</h2>
            </Link>
            <Link to="/content">
              <lord-icon
                src="https://cdn.lordicon.com/jgeruqwm.json"
                trigger="loop"
                delay="1000"
                colors="primary:#aff02e,secondary:#f8ffef"
              ></lord-icon>
              <h2 className="font-darumadrop">Contenido</h2>
            </Link>
            {isLoggedIn ? (
              <>
                <Link to="/account-free">
                  <lord-icon
                    src="https://cdn.lordicon.com/rzsnbiaw.json"
                    trigger="loop"
                    delay="1000"
                    state="hover-looking-around"
                    colors="primary:#aff02e,secondary:#f8ffef"
                  ></lord-icon>
                  <h2 className="font-darumadrop">Mi Cuenta</h2>
                </Link>
                <Link to="/config-perfil">
                  <lord-icon
                    src="https://cdn.lordicon.com/zkiskfnp.json"
                    trigger="loop"
                    delay="1000"
                    colors="primary:#aff02e,secondary:#f8ffef"
                  ></lord-icon>
                  <h2 className="font-darumadrop">Configurar</h2>
                </Link>
                <Link
                  onClick={() => {
                    handleLogout();
                  }}
                >
                  <lord-icon
                    src="https://cdn.lordicon.com/peeuicbd.json"
                    trigger="loop"
                    delay="1000"
                    colors="primary:#aff02e,secondary:#f8ffef"
                  ></lord-icon>
                  <h2 className="font-darumadrop">Cerrar Sesion</h2>
                </Link>
              </>
            ) : (
              <>
                <Link to="/login">
                  <lord-icon
                    src="https://cdn.lordicon.com/rzsnbiaw.json"
                    trigger="loop"
                    delay="1000"
                    state="hover-looking-around"
                    colors="primary:#aff02e,secondary:#f8ffef"
                  ></lord-icon>
                  <h2 className="font-darumadrop">Entrar</h2>
                </Link>
                <Link to="/signup">
                  <lord-icon
                    src="https://cdn.lordicon.com/aojutvlf.json"
                    trigger="loop"
                    delay="1000"
                    colors="primary:#aff02e,secondary:#f8ffef"
                  ></lord-icon>
                  <h2 className="font-darumadrop">Registrarse</h2>
                </Link>
              </>
            )}
          </div>
        </div>
        <div className="desktop-section-2-text">
          <p className="content-homepage-desktop-p1" id="content-home-p1">
            El coach ambientalista es como el superhéroe del planeta, motivando
            a todos a reducir su huella ecológica y proteger nuestro hogar. No
            solo guía a las personas hacia un cambio positivo, sino que también
            lidera acciones voluntarias en momentos de crisis ambiental. ¡Es
            hora de unirnos y salvar el planeta juntos! <br />
            <IoMdCloseCircle
              className="button-chatbot-close-homepage-desktop"
              id="button-chatbot-hidden"
              onClick={closeViewP1}
            />
          </p>
          <p className="content-homepage-desktop-p2" id="content-home-p2">
            Imagina a un coach ambientalista como el guerrero verde que trabaja
            con todos: individuos, empresas, escuelas, ¡incluso tu comunidad! Su
            misión es empoderar a todos para tomar acciones que protejan el
            medio ambiente y mejoren nuestras vidas. ¡Es el héroe que todos
            necesitamos para un futuro más verde y brillante! <br />
            <IoMdCloseCircle
              className="button-chatbot-close-homepage-desktop"
              id="button-chatbot-hidden"
              onClick={closeViewP2}
            />
          </p>
        </div>
        <iframe
          className="homeDesktop-iframe-chatbot"
          id="chatBot"
          src="https://retune.so/share/chat/11ee5794-2bd8-34f0-899f-036ecb8650de/widget"
          title="Chatbot de re:tune.so"
        />
      </div>
    </main>
  );
};
export default IndexDesktop;
