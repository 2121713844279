import React from "react";
import { useUser } from "../context/providers/userContext";
import AdminUsers from "../components/ui/AdminPanel/AdminUsers";
import { useEffect } from "react";

const AdminPage = () => {
  const { getAllUsers, allUsers } = useUser();

  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <main className="main-admin-panel">
      <div className="section">
        <h1 className="font-darumadrop">PANEL DE ADMINISTRACION</h1>
        <AdminUsers users={allUsers} />
      </div>
    </main>
  );
};

export default AdminPage;
