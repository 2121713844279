import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";

import { useUser } from "../../context/providers/userContext";
import "../../styles/signup.css";
import { Spinner } from "../../components/ui/Spinner";
import { useCode } from "../../context/providers/codeContext";

const SignUp = () => {
  const navigate = useNavigate();
  const { postUser, isLoading, isLoggedIn } = useUser();

  const {
    loadCodes,
    isLoading: isloadingCodes,
    verifyCode,
    accessCode,
  } = useCode();

  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    number: "",
    document: "",
    ocupation: "",
    country: "",
    code: "",
  });

  const [loadingCode, setLoadingCode] = useState(true);

  const params = new URLSearchParams(window.location.search);
  const codeParam = params.get("code");
  const start = async () => {
    try {
      await loadCodes();
      await verifyCode(codeParam);
      setLoadingCode(false);
    } catch (error) {
      setLoadingCode(false);
      toast.error("Codigo invalido", {
        position: "bottom-right",
      });
    }
  };

  const handleSubmit = async (values) => {
    try {
      const { confirmPassword, ...userData } = values;
      const userResponse = await postUser(userData);
      if (userResponse) {
        toast.success(`Hola ${userData.name}, te damos la bienvenida.`, {
          position: "bottom-right",
        });
        navigate("/account");
      }
    } catch (error) {
      toast.error(`${error}`, {
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    setUser((prevUser) => ({
      ...prevUser,
      code: codeParam,
    }));
  }, []);

  useEffect(() => {
    start();
  }, [codeParam]);

  return (
    <main className="main">
      {isLoading || loadingCode ? (
        Spinner
      ) : (
        <section className="section-signup">
          {!isloadingCodes ? (
            <>
              {accessCode ? (
                <>
                  {accessCode?.user ? (
                    <>
                      <div>Este codigo pertenece a</div>
                      <ul>
                        <li>{accessCode.user.name}</li>
                        <li>{accessCode.user.document}</li>
                        <li>{accessCode.user.email}</li>
                        {accessCode.user.complete === 3 ? (
                          <li>Su certificacion ha sido completada.</li>
                        ) : (
                          <li>Su certificacion aun no ha sido completada.</li>
                        )}
                      </ul>
                    </>
                  ) : (
                    <>
                      {!isLoggedIn ? (
                        <div className="form">
                          <Formik
                            initialValues={user}
                            validationSchema={Yup.object({
                              name: Yup.string().required("*"),
                              email: Yup.string()
                                .required("*")
                                .email("Debe ser un email valido.")
                                .matches(
                                  /^[^@]+@[^@]+\..+$/,
                                  "Debe ser un email valido."
                                ),
                              password: Yup.string()
                                .required("*")
                                .min(
                                  6,
                                  "La contraseña debe tener minimo 6 caracteres."
                                ),
                              confirmPassword: Yup.string().oneOf(
                                [Yup.ref("password"), null],
                                "Las contraseñas deben coincidir."
                              ),
                              number: Yup.number().required("*"),
                              document: Yup.number().required("*"),
                              ocupation: Yup.string().required("*"),
                              country: Yup.string().required("*"),
                            })}
                            onSubmit={handleSubmit}
                            className="form"
                          >
                            {({ isSubmitting }) => (
                              <Form>
                                <ErrorMessage
                                  className="error-message"
                                  component="p"
                                  name="name"
                                />
                                <div className="input-label-signup">
                                  <div className="img-icon">
                                    <img
                                      className="icono"
                                      src="https://i.postimg.cc/90Z7VdBJ/pngegg.png"
                                      alt=""
                                    />
                                  </div>
                                  <Field
                                    className="input-signup"
                                    name="name"
                                    id="name"
                                    required
                                  />
                                  <label
                                    className="label-signup"
                                    htmlFor="name"
                                  >
                                    Nombre
                                  </label>
                                </div>
                                <ErrorMessage
                                  className="error-message"
                                  component="p"
                                  name="email"
                                />
                                <div className="input-label-signup">
                                  <div className="img-icon">
                                    <img
                                      className="icono"
                                      src="https://i.postimg.cc/fL6962YV/correo.png"
                                      alt=""
                                    />
                                    <img src="" alt="" />
                                  </div>
                                  <Field
                                    className="input-signup"
                                    name="email"
                                    type="text"
                                    required
                                    autoComplete="username"
                                  />
                                  <label
                                    className="label-signup"
                                    htmlFor="email"
                                  >
                                    Email
                                  </label>
                                </div>
                                <ErrorMessage
                                  className="error-message"
                                  component="p"
                                  name="number"
                                />
                                <div className="input-label-signup">
                                  <div className="img-icon">
                                    <img
                                      className="icono"
                                      src="https://i.postimg.cc/4dKc2kHC/TELEFONO.png"
                                      alt=""
                                    />
                                    <img src="" alt="" />
                                  </div>
                                  <Field
                                    className="input-signup"
                                    name="number"
                                    type="number"
                                    required
                                  />
                                  <label
                                    className="label-signup"
                                    htmlFor="number"
                                  >
                                    Numero
                                  </label>
                                </div>
                                <ErrorMessage
                                  className="error-message"
                                  component="p"
                                  name="document"
                                />
                                <div className="input-label-signup">
                                  <div className="img-icon">
                                    <img
                                      className="icono"
                                      src="https://i.postimg.cc/g028R6Y2/identity-verification-icon-copia.png"
                                      alt=""
                                    />
                                    <img src="" alt="" />
                                  </div>
                                  <Field
                                    className="input-signup"
                                    name="document"
                                    type="number"
                                    required
                                  />
                                  <label
                                    className="label-signup"
                                    htmlFor="document"
                                  >
                                    Documento
                                  </label>
                                </div>
                                <ErrorMessage
                                  className="error-message"
                                  component="p"
                                  name="password"
                                />
                                <div className="input-label-signup">
                                  <div className="img-icon">
                                    <img
                                      className="icono"
                                      src="https://i.postimg.cc/2SwnVxNt/CONTRASE-A.png"
                                      alt=""
                                    />
                                    <img src="" alt="" />
                                  </div>
                                  <Field
                                    className="input-signup"
                                    name="password"
                                    type="password"
                                    required
                                    autoComplete="current-password"
                                  />
                                  <label
                                    className="label-signup"
                                    htmlFor="password"
                                  >
                                    Contraseña
                                  </label>
                                </div>
                                <ErrorMessage
                                  className="error-message"
                                  component="p"
                                  name="confirmPassword"
                                />
                                <div className="input-label-signup">
                                  <div className="img-icon">
                                    <img
                                      className="icono"
                                      src="https://i.postimg.cc/2SwnVxNt/CONTRASE-A.png"
                                      alt=""
                                    />
                                    <img src="" alt="" />
                                  </div>
                                  <Field
                                    className="input-signup"
                                    name="confirmPassword"
                                    type="password"
                                    required
                                    autoComplete="current-password"
                                  />
                                  <label
                                    className="label-signup"
                                    htmlFor="password"
                                  >
                                    Confirmar Contraseña
                                  </label>
                                </div>
                                <ErrorMessage
                                  className="error-message"
                                  component="p"
                                  name="ocupation"
                                />
                                <div className="input-label-signup">
                                  <div className="img-icon">
                                    <img
                                      className="icono"
                                      src="https://i.postimg.cc/BQfFpzqy/Pngtree-men-s-work-bag-icon-4635496.png"
                                      alt=""
                                    />
                                    <img src="" alt="" />
                                  </div>
                                  <Field
                                    className="input-signup"
                                    name="ocupation"
                                    required
                                  />
                                  <label
                                    className="label-signup"
                                    htmlFor="ocupation"
                                  >
                                    Ocupacion
                                  </label>
                                </div>

                                <div className="input-label-signup">
                                  <Field
                                    className="input-signup"
                                    name="country"
                                    required
                                  />
                                  <label
                                    className="label-signup"
                                    htmlFor="country"
                                  >
                                    Ciudad
                                  </label>
                                  <div className="img-icon world">
                                    <img
                                      className="icono"
                                      src="https://i.postimg.cc/gcZRvpP8/mundo.png"
                                      alt=""
                                    />
                                  </div>
                                  <ErrorMessage
                                    className="error-message-country"
                                    component="p"
                                    name="country"
                                  />
                                </div>

                                {/* <Field name="image">
                            {({ form }) => (
                              <input
                                type="file"
                                onChange={(event) =>
                                  form.setFieldValue(
                                    "image",
                                    event.currentTarget.files[0]
                                  )
                                }
                              />
                            )}
                          </Field> */}

                                <button
                                  className="button-enter-signup"
                                  type="submit"
                                  disabled={isSubmitting || isLoading}
                                >
                                  {isLoading
                                    ? "Cargando..."
                                    : "SUMATE A LA ACCION"}
                                </button>
                                <Link
                                  to="/conditions"
                                  className="terminosycondiciones"
                                >
                                  Terminos & Condiciones
                                </Link>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      ) : (
                        <div>
                          <h1>No pertenece a nadie</h1>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {!isLoggedIn ? (
                    <div>
                      <h1>Acceso Denegado!</h1>
                      <h4>
                        Obten tu codigo{" "}
                        <span>
                          <a href="https://wa.link/7iv8gd" target="_blank">
                            aqui!
                          </a>
                        </span>
                      </h4>
                    </div>
                  ) : (
                    <div>
                      <h1>El codigo no es valido.</h1>
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            Spinner
          )}
        </section>
      )}
    </main>
    // </div>
  );
};

export default SignUp;
